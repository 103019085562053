import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  /* min-height: 500px; */
  height: 100%;
  background-color: #0b0a45;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  padding: 1rem;
  flex: 2;
`
const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0rem;
  }
`
const Box = styled.div`
  width: 40vw;
  background-color: #0b0a45;
  @media only screen and (max-width: 700px) {
    width: 95vw;
  }
`
const NewsBox = styled.div`
  height: 500px;
  width: 40vw;
  padding: 0.5rem;
  overflow-y: auto;
  @media only screen and (max-width: 700px) {
    width: 95vw;
  }
`
const NewsOne = styled.div`
  margin: 0.4rem;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 0.75rem;
  background-color: #ffffff;
  color: #0b0a45;
  margin: 1rem;
  font-weight: 300;
  /* border: 2px solid #ffffff; */
`
const Heading = styled.h1`
  font-size: 2rem;

  font-weight: 400;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`

const Left = styled.div`
  flex: 0.8;
`
const Right = styled.div`
  flex: 1.2;
  height: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 700px) {
    display: none;
    visibility: hidden;
  }
`

const GalleryWrapper = styled.article`
  --size: 100px;
  display: grid;
  grid-template-columns: repeat(6, var(--size));
  grid-auto-rows: var(--size);
  margin-bottom: var(--size);
  place-items: start center;
  gap: 5px;

  &:hover img:not(:hover),
  &:focus img:not(:focus) {
    filter: brightness(0.5) contrast(0.5);
  }

  & img {
    object-fit: cover;
    width: calc(var(--size) * 2);
    height: calc(var(--size) * 2);
    clip-path: path(
      'M90,10 C100,0 100,0 110,10 190,90 190,90 190,90 200,100 200,100 190,110 190,110 110,190 110,190 100,200 100,200 90,190 90,190 10,110 10,110 0,100 0,100 10,90Z'
    );
    transition: clip-path 0.25s, filter 0.75s;
    grid-column: auto / span 2;
    border-radius: 5px;

    &:nth-child(5n - 1) {
      grid-column: 2 / span 2;
    }

    &:hover,
    &:focus {
      clip-path: path(
        'M0,0 C0,0 200,0 200,0 200,0 200,100 200,100 200,100 200,200 200,200 200,200 100,200 100,200 100,200 100,200 0,200 0,200 0,100 0,100 0,100 0,100 0,100Z'
      );
      z-index: 1;
      transition: clip-path 0.25s, filter 0.25s;
    }

    &:focus {
      outline: 1px dashed black;
      outline-offset: -5px;
    }
  }
`

const Imp = styled.div`
  height: 3rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #25265f;
  font-weight: 600;
`
const Gallery = () => {
  return (
    <>
      <Imp>News & Highlights</Imp>
      <Container>
        <Left>
          <Wrapper>
            <Box>
              <Heading>What's New ? </Heading>
            </Box>
            <NewsBox>
              <NewsOne>
                We are currently seeking passionate and dedicated educators to
                join our team. If you're enthusiastic about shaping young minds
                and fostering a love for learning, we invite you to explore
                teaching positions at our institution.Join us in creating a
                positive educational impact. Apply today and be a part of our
                commitment to excellence in teaching.
              </NewsOne>
              <NewsOne>
                Orion is excited to announce open management positions within
                our institution. If you're a dynamic and motivated individual
                with a passion for education and a drive to make a positive
                impact, we encourage you to explore management roles at our
                school.
              </NewsOne>
              <NewsOne>
                Safety is our priority, and we're looking for dedicated
                individuals to join our security team at Orion. If you are
                committed to ensuring a secure and welcoming environment for our
                students, staff, and visitors, we encourage you to explore the
                security positions available.Apply today and be part of our
                commitment to safety and security.
              </NewsOne>
              <NewsOne>
                We're thrilled to announce that admissions for the upcoming
                academic year at Orion will be opening soon. We're dedicated to
                providing a high-quality education and welcoming new students
                who are eager to embark on a journey of learning, growth, and
                discovery.
              </NewsOne>
            </NewsBox>
          </Wrapper>
        </Left>
        <Right>
          <GalleryWrapper>
            <img
              src='https://images.pexels.com/photos/8466776/pexels-photo-8466776.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8613121/pexels-photo-8613121.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8535236/pexels-photo-8535236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/1089069/pexels-photo-1089069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8612995/pexels-photo-8612995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8535147/pexels-photo-8535147.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8535193/pexels-photo-8535193.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              alt='the orion school'
            />
            <img
              src='https://images.pexels.com/photos/8612967/pexels-photo-8612967.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              alt='the orion school'
            />
          </GalleryWrapper>
        </Right>
      </Container>
    </>
  )
}

export default Gallery
